import * as dd from 'dingtalk-jsapi';
import { corpId, baseUrl } from "../utils/config.js";
// import request from './request.js';
import {toast, setTokenToCache } from '../utils/common.js';
import axios from 'axios';
// import store from '../store/index.js';
// import router from '../router'

// 登录 用独立的axios
export function login(){
    return new Promise(function(resolve, reject){
        dd.runtime.permission.requestAuthCode({
            corpId: corpId, // 企业id
            onSuccess: function (info) {
                var code = info.code // 通过该免登授权码可以获取用户身份
                var data = {code: code}
                    ,url = '/ding_login';
                dd.device.notification.showPreloader({
                        text:'正在登录...'
                    });
                var config = {
                    method: 'post', // 默认值
                    baseURL: baseUrl,
                    responseType: 'json', // 默认值
                    data,
                    url,
                }
                return axios.request(config).then((res)=>{
                    dd.device.notification.hidePreloader();
                    toast('登录成功', 'success');
                    var {token,  expires_time} = res.data
                    // store.dispatch('LOGIN', token);
                    setTokenToCache(token, expires_time);
                    resolve(token);
                })
            },
            onFail : function(error) {
                toast(error.errorMessage, 'error');
                reject(error);
            }
        });
    })
}


// export function getUser(){
//     var url = `/ding_user`;
//     return request({
//         method: 'get',
//         url,
//     }, true);
// }
    