import * as dd from 'dingtalk-jsapi';
import { baseUrl, LOGINTOKEN, USERINFO, gensetConfigs, engineConfigs } from "./config.js";
import axios from 'axios';
import { login } from '../api/login.js';

import store from '../store/index.js';
var loading = '正在请求...';
// var token = store.state.token;

// 做一下包装(第一个是方法，第二个是参数)
export function ddProxeJSApi(func, data){
    return new Promise(function(resolve, reject){
        var args = Object.assign({
            onSuccess : function(res) {
                resolve([null, res]);
            },
            onFail : function(err) {
                reject([err, null]);
            }
        }, data);
        
        func(args);
    }) 
}

// get 请求
export function httpGet(url, params, auth = false){
    //axios.defaults.headers[TOKENNAME] = 'Bearer ' + store.state.token;
    
    const http = axios.create({
      baseURL: baseUrl,
      headers: {'Authori-zation': 'Bearer ' + store.state.token}
    });
    return new Promise(function(resolve, reject){
		// 如果需要登录， 且无token ，提示未登录
		if(auth && !store.state.token){
			reject([new Error('未登录'), null]);
		}
        dd.device.notification.showPreloader({
                text:loading
            });
        http.get(url, { params}, ).then((res)=> {
            dd.device.notification.hidePreloader();
            var data = res.data;
            if(data.status != 200){
                reject([data, null]);
            }else{
                resolve([null, data]);
            }
            
        }).catch((err)=> {
            dd.device.notification.hidePreloader();
            reject([err, null]);
        });
    }) 
}

// post 请求
export function httpPost(url, data, auth = false){
    return new Promise(function(resolve, reject){
		// 如果需要登录， 且无token ，提示未登录
		if(auth && !store.state.token){
			reject([new Error('未登录'), null]);
		}
        dd.device.notification.showPreloader({
                text:loading
            });
        const http = axios.create({
          baseURL: baseUrl,
          headers: {'Authori-zation': 'Bearer ' + store.state.token}
        });
        http.post(url, data).then((res)=> {
            dd.device.notification.hidePreloader();
            var data = res.data;
            if(data.status != 200){
                reject([data, null]);
            }else{
                resolve([null, data]);
            }
        }).catch((err)=> {
            dd.device.notification.hidePreloader();
            reject([err, null]);
        });
    }) 
}

// alert 弹框
export async function alert(message){
    var args = {
        message,
        title: "提示",
        buttonName: "确定"
       };
    return ddProxeJSApi(dd.device.notification.alert, args);
}

// alert 弹框
export async function prompt(message){
    var args = {
        message,
        title: "提示",
        buttonName: "确定"
       };
    return ddProxeJSApi(dd.device.notification.prompt, args);
}

/**
 * @param {Object} text 弹框信息
 * @param {Object} icon //success和error
 */
export async function toast(text, icon){
    var args = {
        icon: icon || '', 
        text, 
    };
    return ddProxeJSApi(dd.device.notification.toast, args);
}

export function confirm(msg, ok, cancel){
        dd.device.notification.confirm({
        message: msg,
        title: "提示",
        buttonLabels: ['取消', '确定'],
        onSuccess : function(result) {
            if(result.buttonIndex == 1){
                ok && ok();
            }else if(result.buttonIndex == 0){
                cancel && cancel();
            }
        },
    });
}
// 存用户信息到本地
export async  function setItem(key, data){
      var args = {
          name: key,
          value: data,
      };
      return ddProxeJSApi(dd.util.domainStorage.setItem, args);
}

// 获取本地用户信息
export async  function getItem(key){
      var args = {
          name: key
      };
      return ddProxeJSApi(dd.util.domainStorage.getItem, args);
}

// 删除本地用户信息
export async  function removeItem(key){
      var args = {
          name: key
      };
      return ddProxeJSApi(dd.util.domainStorage.removeItem, args);
}

// 用户信息 设置 存取 token信息的存取
/**
    {
       errorMessage:"错误信息",// errorMessage 信息会展示出钉钉服务端生成签名使用的参数，请和您生成签名的参数作对比，找出错误的参数
       errorCode: "错误码"
    }
       **/
// token ''
export function setTokenToCache(token, exp){
    var data = {
        token,
        exp
    }
    return new Promise(function(resolve, reject){
        try{
            localStorage.setItem(LOGINTOKEN, JSON.stringify(data));
            resolve(data);
        }catch(ex){
            reject(ex);
        }
        
        
        // dd.util.domainStorage.setItem({
        //      name: LOGINTOKEN , // 存储信息的key值
        //      value: JSON.stringify(data), // 存储信息的Value值
        //      onSuccess : function() {
        //           resolve(data);
        //      },
        //      onFail : function(err) {
        //           reject(err)
        //      }
        //  });
    }) 
}

export function getTokenFromCache(){
    var cur = Math.floor(Date.now()/1000);
    return new Promise(function(resolve, reject){
        var data = localStorage.getItem(LOGINTOKEN);
        if(!data){
            reject({
                errorMessage: '信息未发现',
                errorCode: 40001,
            });
        }
        data = JSON.parse(data);
        if(cur < data.exp){
           resolve(data.token);
        }
        reject({
            errorMessage: 'token已过期',
            errorCode: 40001,
        });
        
        // dd.util.domainStorage.getItem({
        //      name:LOGINTOKEN , // 存储信息的key值
        //      onSuccess : function(info) {
        //          var value = info.value;
                 
        //          if(value){
        //              value = JSON.parse(value);
        //              if(cur < value.exp){
        //                 resolve(value.token);
        //              }
        //              reject({
        //                  errorMessage: 'token已过期',
        //                  errorCode: 40001,
        //              });
        //          }else{
        //              reject({
        //                  errorMessage: '信息未发现',
        //                  errorCode: 40001,
        //              });
        //          }
                
        //      },
        //      onFail : function(err) {
        //         reject(err);
        //      }
        //  });
    }) 
}

export function setUserToCache(user){
    return new Promise(function(resolve, reject){
        dd.util.domainStorage.setItem({
             name: USERINFO , // 存储信息的key值
             value: user, // 存储信息的Value值
             onSuccess : function(info) {
                  resolve(info);
             },
             onFail : function(err) {
                  reject(err)
             }
         });
    }) 
}

export function getUserFromCache(){
    return new Promise(function(resolve, reject){
        dd.util.domainStorage.getItem({
             name:USERINFO , // 存储信息的key值
             onSuccess : function(info) {
                if(info.value){
                   return resolve(info.value);
                }
                reject({
                    errorMessage: '信息未发现',
                    errorCode: 40001,
                });
             },
             onFail : function(err) {
                 console.log('getUserFromCache err');
                reject(err);
             }
         });
    }) 
}

export function formatSecond(_s){
    var h,m,s;
    if(_s>=3600){
        // 显示小时
        h = Math.floor(_s/3600);
        m = Math.floor(_s/60);
        s = _s%60;
        return `${(h+'').padStart(2, '0')}:${(m+'').padStart(2, '0')}:${(s+'').padStart(2, '0')}`;
    }else{
        m = Math.floor(_s/60);
        s = _s%60;
        return `${(m+'').padStart(2, '0')}:${(s+'').padStart(2, '0')}`;
    }
}

export function formatDate(){
    var d = new Date();
    return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
}

export function checkLogin(){
    return getTokenFromCache().then((token)=>{
        store.dispatch('LOGIN', token);
        return Promise.resolve(token);
    }).catch(()=>{
        return login().then((token)=>{
            store.dispatch('LOGIN', token);
            return Promise.resolve(token);
        })
    })
}

// 填充文本 如果内容超出就截断 最多绘制两段
function formatFillText(ctx, text, posx, posy, width, height){

    var arr = subStr(text, width);
    if(arr.length == 1){
        ctx.fillText(arr[0], posx + width/2, posy + height / 5 * 3, width);
    }else if(arr.length == 2){
        ctx.fillText(arr[0], posx + width/2, posy + height / 5 * 2, width);
        ctx.fillText(arr[1], posx + width/2, posy + height / 5 * 4, width);
    }
    
}
// 截取字符串（按中英文）
function subStr(str, width) {
    if(!str){
        return [];
    }
    var cellLength = Math.floor(width / 10);
    var str1 = '';
    var str2 = '';
    var cur = 0;
    for (var i = 0; i < str.length; i++) {
        var l = 1;
        var c = str.charCodeAt(i);
        //中文字符的Unicode编码值范围
        if (c >= 0x4e00 && c <= 0x9fa5) {
            l  = 2;
        }
        if(cur + l <= cellLength){
            str1 += str[i];
            cur += l;
        }else if(cur + l <= 2 * cellLength){
            str2 += str[i];
            cur += l;
        }else{
            return [ str1, str2 ];
        }
    }
    return str2? [ str1, str2 ]: [ str1 ];
}

export function drawGenset(list, container){
    container.innerHTML = ''
    if(!list || list.length == 0){
       return;
    }
    // 绘制内容
    for (let index = 0; index < list.length; index++) {
        const data = list[index];
        var ele = drawGensetItem(data);
        // console.log(ele);
        container.append(ele);
    }
}

function drawGensetItem(item){
    var itemConfig = typeof(item.config)=='string'?JSON.parse(item.config): item.config;
    var width = gensetConfigs.reduce((memo, item)=> {
        return memo += item.width;
    }, 0);
    var rowHeight = 50;
    var headerH = 60;
    var footerH = 120;
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = rowHeight * itemConfig.length + rowHeight + headerH + footerH;
    //console.log('canvas.height', item.config[0])
    // 标题
    ctx.beginPath();
    var posx = 0, posy = 0;
    ctx.fillStyle = '#000';
    ctx.font = ' bold 24px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(item.title, width / 2, headerH / 2, width);

    posy += headerH;
    ctx.stroke();
    
    ctx.beginPath();
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, headerH, canvas.width, canvas.height - headerH);
    ctx.lineWidth = 0.5;
    ctx.strokeStyle = '#000';

    ctx.beginPath();
    ctx.rect(0, headerH, canvas.width, canvas.height - headerH);
    ctx.stroke();

    ctx.fillStyle = '#000';
    ctx.font = '16px Arial';

    // 绘制表头
    for (let index = 0; index < gensetConfigs.length; index++) {
        var config =  gensetConfigs[index];
        // 文字
        ctx.fillText(config.title, posx + config.width/2, posy + rowHeight/2, config.width);
        posx += config.width;
        // 竖线
        if(index != gensetConfigs.length - 1){
            ctx.beginPath();
            ctx.moveTo(posx, posy);
            ctx.lineTo(posx, posy + rowHeight);
            ctx.stroke();
        }
    }
    posy += rowHeight;
    // 绘制内容
    
    for (let index = 0; index < itemConfig.length; index++) {
        const detail = itemConfig[index];

        //因为有表头 先画横线
        ctx.lineWidth = 0.5;
        ctx.beginPath();
        ctx.moveTo(0, posy);
        ctx.lineTo(posx, posy);
        ctx.stroke();

        for (let j = 0; j < gensetConfigs.length; j++) {
            if(j == 0){
                posx = 0;
            }
            config =  gensetConfigs[j];
            // 文字
            //ctx.fillText(detail[config.key], posx + config.width/2, posy + rowHeight/2, config.width);
            formatFillText(ctx, detail[config.key], posx, posy, config.width,  rowHeight);
            posx += config.width;
            // 竖线
            if(j != gensetConfigs.length - 1){
                ctx.lineWidth = 0.5;
                ctx.beginPath();
                ctx.moveTo(posx, posy);
                ctx.lineTo(posx, posy + rowHeight);
                ctx.stroke();
            } 
        }
        posy += rowHeight;
    }
    // 数据结尾横线
    ctx.beginPath();
    ctx.moveTo(0, posy);
    ctx.lineTo(posx, posy);
    ctx.stroke();

    formatFillText(ctx, item.remark, 0, posy, width,  footerH);

    // 绘制底部
    
    var img = canvas.toDataURL('image/png');
    var div = document.createElement('div');
    div.className = 'order-detail-item';
    var imgDom = document.createElement('img');
    imgDom.src = img;
    console.log('imgDom', imgDom);
    div.append(imgDom);
    return div;
    //document.getElementById('list').append(item);
    //document.getElementById('photo').src = img;

}

export function drawEngine(list, container){
    container.innerHTML = ''
    if(!list || list.length == 0){
       return;
    }
    // 绘制内容
    var width = engineConfigs.reduce((memo, item)=> {
        return memo += item.width;
    }, 0);
    var rowHeight = 50;
    var headerH = 60;
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = rowHeight * list.length + rowHeight + headerH ;

    ctx.beginPath();
    var posx = 0, posy = 0;
    ctx.fillStyle = '#000';
    ctx.font = ' bold 24px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('柴油机生产任务单', width / 2, headerH / 2, width);

    posy += headerH;
    ctx.stroke();
    
    ctx.beginPath();
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, headerH, canvas.width, canvas.height - headerH);
    ctx.lineWidth = 0.5;
    ctx.strokeStyle = '#000';

    // 绘制表格
    ctx.beginPath();
    ctx.rect(0, headerH, canvas.width, canvas.height - headerH);
    ctx.stroke();
    ctx.fillStyle = '#000';
    ctx.font = '16px Arial';

    // 表头
    for (let index = 0; index < engineConfigs.length; index++) {
        const config = engineConfigs[index];
        // 文字
        ctx.fillText(config.title, posx + config.width/2, posy + rowHeight/2, config.width);
        posx += config.width;
        // 竖线
        if(index != engineConfigs.length - 1){
            ctx.beginPath();
            ctx.moveTo(posx, posy);
            ctx.lineTo(posx, posy + rowHeight);
            ctx.stroke();
        }
    }
    posy += rowHeight;

    // 表头横线
    ctx.beginPath();
    ctx.moveTo(0, posy);
    ctx.lineTo(posx, posy);
    ctx.stroke();


    for (let index = 0; index < list.length; index++) {
        const detail =  typeof(list[index].config) == 'string'?JSON.parse(list[index].config):list[index].config;
        posx = 0;
        for (let j = 0; j < engineConfigs.length; j++) {
            const config = engineConfigs[j];
            formatFillText(ctx, detail[config.key], posx, posy, config.width,  rowHeight);
            posx += config.width;
            // 竖线
            // console.log('suxian');
            if(j != engineConfigs.length - 1){
                ctx.beginPath();
                ctx.moveTo(posx, posy);
                ctx.lineTo(posx, posy + rowHeight);
                ctx.stroke();
            } 
        }
        posy += rowHeight;
        // 表头横线
        ctx.beginPath();
        ctx.moveTo(0, posy);
        ctx.lineTo(posx, posy);
        ctx.stroke();
    }

    var img = canvas.toDataURL('image/png');
    var item = document.createElement('div');
    item.className = 'order-detail-item';
    var imgDom = document.createElement('img');
    imgDom.src = img;
    console.log(imgDom);
    item.append(imgDom);
    container.append(item);
}