import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import * as dd from 'dingtalk-jsapi'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{
        title: '首页'
    },
    component: Home
  },
  
  {
    path: '/search',
    name: 'Search',
    meta:{
        title: '搜索'
    },
    component: () => import('../views/Search.vue')
  },
  {
    path: '/my',
    name: 'My',
    meta:{
        title: '我的'
    },
    component: () => import('../views/My.vue')
  },
  {
    path: '/exam',
    name: 'Exam',
    meta:{
        title: '试卷'
    },
    component: () => import('../views/exam/Exam.vue')
  },
  {
    path: '/question',
    name: 'Question',
    meta:{
        title: '考试中'
    },
    component: () => import('../views/exam/Question.vue')
  },
  {
    path: '/result',
    name: 'Result',
    meta:{
        title: '考试结果'
    },
    component: () => import('../views/exam/Result.vue')
  },
  {
    path: '/result-detail',
    name: 'ResultDetail',
    meta:{
        title: '考试详情'
    },
    component: () => import('../views/exam/ResultDetail.vue')
  },
  {
    path: '/task',
    name: 'Task',
    meta:{
        title: '我的任务'
    },
    component: () => import('../views/task/Task.vue')
  },
  {
    path: '/task-detail',
    name: 'TaskDetail',
    meta:{
        title: '任务详情'
    },
    component: () => import('../views/task/TaskDetail.vue')
  },
  {
    path: '/video',
    name: 'Video',
    meta:{
        title: '播放视频'
    },
    component: () => import('../views/task/Video.vue')
  },
  {
    path: '/toffice',
    name: 'Toffice',
    meta:{
        title: 'doc详情'
    },
    component: () => import('../views/task/Toffice.vue')
  },
  {
    path: '/tpdf',
    name: 'Tpdf',
    meta:{
        title: 'pdf详情'
    },
    component: () => import('../views/task/Tpdf.vue')
  },
  {
    path: '/preview',
    name: 'Preview',
    meta:{
        title: '预览视频'
    },
    component: () => import('../views/Preview.vue')
  },
  {
    path: '/ranking',
    name: 'Ranking',
    meta:{
        title: '学分排行榜'
    },
    component: () => import('../views/Ranking.vue')
  },
  {
    path: '/lesson',
    name: 'Lesson',
    meta:{
        title: '课程'
    },
    component: () => import('../views/courseware/Lesson.vue')
  },
  {
    path: '/courseware-detail',
    name: 'CoursewareDetail',
    meta:{
        title: '课件详情'
    },
    component: () => import('../views/courseware/CoursewareDetail.vue')
  },
  {
    path: '/view',
    name: 'View',
    meta:{
        title: '视频播放'
    },
    component: () => import('../views/courseware/View.vue')
  },
  {
    path: '/coffice',
    name: 'Coffice',
    meta:{
        title: 'doc详情'
    },
    component: () => import('../views/courseware/Coffice.vue')
  },
  {
    path: '/cpdf',
    name: 'Cpdf',
    meta:{
        title: 'pdf详情'
    },
    component: () => import('../views/courseware/Cpdf.vue')
  },
  {
    path: '/record',
    name: 'Record',
    meta:{
        title: '学分获得记录'
    },
    component: () => import('../views/Record.vue')
  },
  {
    path: '/collect',
    name: 'Collect',
    meta:{
        title: '我的收藏'
    },
    component: () => import('../views/Collect.vue')
  },
  
  
  {
    path: '/tag',
    name: 'Tag',
    meta:{
        title: '称号说明'
    },
    component: () => import('../views/Tag.vue')
  },
  {
    path: '/erp',
    name: 'Erp',
    meta:{
        title: '生产进度'
    },
    component: () => import('../views/erp/Index.vue')
  },
  {
    path: '/erp/add-order',
    name: 'add-order',
    meta:{
        title: '添加订单'
    },
    component: () => import('../views/erp/AddOrder.vue')
  },
  {
    path: '/erp/order-detail/:id',
    name: 'order-detail',
    meta:{
        title: '订单详情'
    },
    component: () => import('../views/erp/OrderDetail.vue')
  },
  {
    path: '/erp/search-order',
    name: 'search-order',
    meta:{
        title: '搜索订单'
    },
    component: () => import('../views/erp/Search.vue')
  },
  {
    path: '/erp/comment/view-pdf',
    name: 'view-pdf',
    meta:{
        title: '查看PDF'
    },
    component: () => import('../views/erp/ViewPdf.vue')
  },
  {
    path: '/erp/comment/view-doc',
    name: 'view-pdf',
    meta:{
        title: '查看Doc'
    },
    component: () => import('../views/erp/ViewDoc.vue')
  },
  {
    path: '/mes/order/task/:taskid',
    name: 'order-task',
    meta:{
        title: '生产任务',
    },
    component: () => import('../views/mes/Task.vue')
  },
  {
    path: '/mes/order/my',
    name: 'order-task',
    meta:{
        title: '我的',
    },
    component: () => import('../views/mes/My.vue')
  },
  {
    path: '/mes/order/card',
    name: 'order-card',
    meta:{
        title: '绑定工作证',
    },
    component: () => import('../views/mes/Card.vue')
  },
  {
    path: '/hr/attendance/list',
    name: 'hr-attendance',
    meta:{
        title: '考勤记录',
    },
    component: () => import('../views/hr/list.vue')
  },
  {
    path: '/hr/attendance/detail',
    name: 'hr-attendance-detail',
    meta:{
        title: '考勤明细',
    },
    component: () => import('../views/hr/detail.vue')
  }
]

const originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location){
    return originPush.call(this, location).catch(err => err);
}
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function(location){
    return originReplace.call(this, location).catch(err => err);
}
const router = new VueRouter({
  mode: 'hash',// history
  routes
})

router.afterEach((to) => {
    // 设置标题
    setTimeout(()=>{
        console.log(to, dd);
        dd.biz.navigation.setTitle({
            title : to.meta.title
        });
    }, 100);
    return false;
})

export default router
