
class Cache {

    constructor() {
        // this.cacheSetHandler = localStorage.setItem;
        // this.cacheGetHandler = localStorage.getItem;
        // this.cacheClearHandler = localStorage.removeItem;
    }

    /**
     * 获取当前时间戳
     */
    time() {
        return Math.round(new Date() / 1000);
    }
    
    /**
     * 字符串转时间戳
     * @param {Object} expiresTime
     */
    strTotime(expiresTime){
        let expires_time = expiresTime.substring(0, 19);
        expires_time = expires_time.replace(/-/g, '/');
        return Math.round(new Date(expires_time).getTime() / 1000);
    }
    

    /**
     * 设置缓存
     * @param {Object} key
     * @param {Object} data
     */
    set(key, data, expire) {
        var obj = {
            'value': data
        }
        if(expire){
            obj['expire'] = expire
        }
        localStorage.setItem(key, JSON.stringify(obj));
    }


    /**
     * 获取缓存
     * @param {Object} key
     * @param {Object} $default
     * @param {Object} expire
     */
    get(key) {
        // 获取值时要先看是否过期
        var data = localStorage.getItem(key);
        if(!data){
            return null;
        }
        data = JSON.parse(data);
        
        if(!data.expire){
            return data.value;
        }
        var time = this.time();
        // 如果当前时间小于过期时间，就不过期
		console.log('data', time - data.expire);
        if(time - data.expire > 0){
            this.clear(key);
            return null;
        }else{
            return data.value;
        }
    }

    /**
     * 删除缓存
     * @param {Object} key
     */
    clear(key) {
        localStorage.removeItem(key);
    }

}


export default new Cache;
