// 封装 http
import axios from 'axios';
import store from '../store/index.js';
// import {login} from './common.js';
import { baseUrl } from '../utils/config.js';
// import { getTokenFromCache } from '../utils/common.js';

axios.defaults.baseURL = baseUrl;
axios.defaults.responseType = 'json';
axios.defaults.method = 'get';
axios.defaults.timeout = 10000;

// let logoing = false
const request = (options) =>{
    var token = store.state.token;
    options['headers'] = {'Authori-zation': 'Bearer ' + token };
    return axios.request(options);
}

//处理axios多封的一层data
// 添加响应拦截器
axios.interceptors.response.use(function (res) {
    var data = res.data;
    // if(!data.status){
    //     return Promise.resolve(data);
    // }
    if(data.status != 200){
        if(data.status == 110002){
            console.log(data);
        }
        // 不是200 返回 错误
        return Promise.reject(new Error(data.msg));
    }
    return Promise.resolve(data);
  }, function (error) {
    return Promise.reject(error);
  });
  
export default request;