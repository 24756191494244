<template>
  <div id="app">
    <van-tabbar v-model="active" route id="vantab" @change="onChange" class="home-tab">
        <!--?dd_nav_bgcolor=ff725efd-->
      <van-tabbar-item icon="home-o" name="Home" replace to="/?dd_nav_bgcolor=ff725efd">
          <div :class="active=='Home'?'van-tabbar-item van-tabbar-item--active':'van-tabbar-item'">首页</div>
      </van-tabbar-item>
      <van-tabbar-item icon="bag-o" name="Task" replace to="/task">任务</van-tabbar-item>
      <van-tabbar-item icon="notes-o" name="Lesson" replace to="/lesson">课程</van-tabbar-item>
     <!-- <van-tabbar-item icon="search" replace 
        to="/preview?id=3&tsid=11&cur=0">视频</van-tabbar-item> -->
      <van-tabbar-item icon="user-o" name="My" replace to="/my">我的</van-tabbar-item>
    </van-tabbar>
    <keep-alive>
        <router-view/>
    </keep-alive>
  </div>
</template>

<script>
// import {login} from './api/common.js';
// import { corpId } from "./utils/config.js";
import {httpGet, toast } from './utils/common.js';
import { mapState, mapActions } from 'vuex'
// 登录流程
// 获取配置信息
//  查看本地缓存用户信息 存在，直接登录
// 不存在，获取免登码，登录

// import store from './store/index.js'
export default {
  data() {
    return {
      active: 'Home',
    };
  },
  computed: mapState(['token', 'user']),
  async created() { 
    if(!this.token){
      // login();
    }
  },
  methods:{
    ...mapActions(['LOGIN', 'UPDATEUSER']),
    onChange(op){
        console.log(op);
    },
    async getUser(){
        var url = `/ding_user`;
        var [err, ret] = await httpGet(url, null, true);
        if(err){
         toast('获取！', 'error');
         return;
        }else{
            toast('获取成功！', 'success');
            console.log(ret.data);
        }
    },
  },
}
</script>


<style>
html{
    background-color: #f2f2f2;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
