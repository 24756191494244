// 服务器地址
export const  baseUrl = 'http://xjbc.fr3.top/api';
export const TOKENNAME = 'Authori-zation';
// 钉钉中企业的 id
export const corpId = 'ding6099bb696358d28effe93478753d9884';

// 每一个都必须设置过期时间
// 用户登录信息
export const LOGINTOKEN = 'LOGIN_TOKEN';
export const USERINFO = 'USER_INFO';
export const EXPIRES_TIME = 'EXPIRES_TIME';

// 定义工序的类型 
export const MesStageTypeDict = {
    normal: 0, // 普通
    check: 1,  // 质检类型
    final: 2, // 最终检查
}

// 页面标题
export const pageTitle = {
    Home : '首页',
    About: '关于',
    Auth : '登录',
    User : '我的',
}

export const gensetConfigs = [
    {
        key: 'name',
        title: '产品名称',
        width: 160,
    },
    {
        key: 'model',
        title: '规格型号',
        width: 160,
    },
    {
        key: 'num',
        title: '数量',
        width: 80,
    },
    {
        key: 'unit',
        title: '单位',
        width: 60,
    },
    {
        key: 'brand',
        title: '品牌',
        width: 120,
    },
    {
        key: 'remark',
        title: '要求',
        width: 240
    }
];

export const engineConfigs = [
    {
        key: 'order_no',
        title: '订单编号',
        width: 100,
    },
    {
        key: 'gno',
        title: '客户代码',
        width: 100,
    },
    {
        key: 'model',
        title: '柴油机型号/功率',
        width: 180,
    },
    {
        key: 'num',
        title: '数量',
        width: 80,
    },
    {
        key: 'ddate',
        title: '交付日期',
        width: 120,
    },
    {
        key: 'remark',
        title: '备注',
        width: 240
    }
];