import Vue from 'vue'
import Vuex from 'vuex'

import { LOGINTOKEN, USERINFO } from '../utils/config.js';
import Cache from '../utils/cache.js';

Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        user: Cache.get(USERINFO)|| null,
        token: '',
    },
    mutations: {
        LOGIN(state, token) {
            state.token = token;
        },
        UPDATEUSER(state, user){
            state.user = user;
        },
        LOGOUT(state) {
            state.token = undefined;
            state.user = undefined;
            Cache.clear(LOGINTOKEN);
            Cache.clear(USERINFO);
        },
        setPageTitle(state, title){
            state.pageTitle = title;
        },
    },
    actions:{
        LOGIN(context, token){
            context.commit('LOGIN', token);
        },
        UPDATEUSER(context, user){
            context.commit('UPDATEUSER', user);
			Cache.set(USERINFO, user);
        },
        LOGOUT(context){
            context.commit('LOGOUT');
        },
    }
})