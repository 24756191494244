<template>
  <div class="home">
      <header></header>
      <div class="container">
        <div class="search">
                <img src="../assets/images/btn-search.png">
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
              <router-link to="/lesson"><img src="../assets/images/banner.jpg"></router-link>
          </van-swipe-item>
          <!-- <van-swipe-item>
              <img src="../assets/banner2.png">
          </van-swipe-item> -->
        </van-swipe>
        
        <div class="notice">
            <van-notice-bar
              :left-icon="tz"
              mode="link"
              color="#666666"
              background="#fff"
              text="通知通知。"
            />
        </div>
        <!-- <van-button type="primary">主要按钮</van-button> -->
        <div class="menus">
            <div class="item" @click="goto('/lesson')">
                <img src="../assets/images/home-zhishi.png">
                <div>课程</div>
            </div>
            
            <div class="item" @click="goto('/task')">
                <img src="../assets/images/home-task.png">
                <div>学习任务</div>
            </div>
            <div class="item" @click="goto('/erp')" v-if="info && info.erp_auth">
                <img src="../assets/images/home-order.png">
                <div>订单</div>
            </div>
            <div class="item" @click="goto('/mes/order/task/0')" >
                <img src="../assets/images/home-mes.png">
                <div>K1-MES</div>
            </div>
            <!-- <div class="item" @click="goto('/mes/order/card')" v-if="info && info.phone=='19861023752'">
                <img src="../assets/images/home-work.png">
                <div>绑定工作证</div>
            </div> -->
        </div>
        <div class="section list" v-if="list.length != 0">
            <div class="section-header">
                <strong>进行中的任务</strong>
                <!-- <a href="#" class="gengduo">更多</a> -->
            </div>
            <div class="section-body">
                <div class="section-body-item"
                    v-for="(item, index) in list"
                    :key="index"
                    @click="toVideo(item.tsid, item.tid, index)"
                    >
                    <img :src="item.picture" >
                    <div class="title text-ellipsis">
                        {{item.name}}
                    </div>
                    <div class="liulan">
                        <span class="" style="padding-right: 10px;">
                            <img :src="getLessonIcon(item.lesson_type)" alt="">{{item.lesson_type==0?format(item.total_num): getLessonTypeName(item.lesson_type)}}
                        </span>
                        <span class="">
                            <img src="../assets/images/item_process.png" alt="">{{getPercent(item)}}%
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section list with-footer">
            <div class="section-header">
                <strong class="text-ellipsis">课件</strong>
            </div>
            <div class="section-body">
                <div class="section-body-item"
                    v-for="(item, i) in clist" :key="i"
                    @click="toDetail(item.id)"
                    >
                    <img :src="item.cover" >
                    <div class="title text-ellipsis">
                        {{item.name}}
                    </div>
                    <div class="liulan flex">
                        <span class="" style="padding-right: 15px;">
                            <!-- <img src="../assets/images/dz-cur.png" alt=""> -->
                            <van-icon name="like" size="15" color="#9e9e9e" />
                            {{getzan(item.statistics)}}
                        </span>
                        <span class="">
                            <!-- <img src="../assets/images/sc-cur.png" alt=""> -->
                            <van-icon name="star" size="16" color="#9e9e9e" />
                            {{getshou(item.statistics)}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getUser, getOngoingLessons, getUntakedTaskCount, getHomeCoursewareList, getMyUnConfirmedAttendanceList} from '../api/task.js';
import {formatSecond, formatDate, checkLogin } from '../utils/common.js';
// import { getUser } from '../api/common.js';
import { setWaterMark } from "../utils/watermark";
export default {
 name: 'Home',
  data() {
    return {
      active: 0,
      show: false,
      tz:require('../assets/images/tz.png'),
      // vd: require('../assets/images/liulan.png'),
      list: [],
      clist: [],
      info: null,
    };
  },
  activated() {
    this.hideTab(1);
    checkLogin().then(()=> {
        this.getPageData();
    })
    
  },
  methods:{
      getPageData(){
          Promise.all([this.getUser(), this.getUnconfirmedAttendance(), this.getLessons()]);
      },
      goto(url){
          this.$router.push(url);
      },
      getUser(){
          getUser().then(({data})=> {
              console.log('home', data);
              if(data){
                  this.info = data;
                  setWaterMark(data.dingtalk.nickname, formatDate());
              }
          });
      },
      toVideo(tsid, tid, index){
          var item = this.list[index];
          if (item.lesson_type == 0) {
              this.$router.push(`/video?id=${tid}&tsid=${tsid}&cur=${index}&lid=${item['id']}`);
          } else if(item.lesson_type == 1 || item.lesson_type == 2){
              this.$router.push(`/toffice?id=${tid}&tsid=${tsid}&cur=${index}&lid=${item['id']}`);
          }else if(item.lesson_type == 3 ){
              this.$router.push(`/tpdf?id=${tid}&tsid=${tsid}&cur=${index}&lid=${item['id']}`);
          }
      },
      getTaskList(){
        return getOngoingLessons().then((ret)=>{
              // 已完成任务
              // 进行中任务
              // 未通过任务
              // 未领取任务
              console.log(ret);
              // this.list = ret.data;
              // this.getUntaked();
              // this.getLessons();
        })
      },
      getUntaked(){
          return getUntakedTaskCount().then((ret)=>{
              console.log(ret.data);
              var that = this;
              var count = ret.data? ret.data.count: 0;
              if(count){
                  var msg = `你有${count}个学习任务尚未领取`;
                  this.$dd.device.notification.confirm({
                    message: msg,
                    title: "提示",
                    buttonLabels: ['取消', '领取'],
                    onSuccess : function(result) {
                        if(result.buttonIndex == 1){
                            that.$router.push('/task');
                        }
                    }
                });
              }
          })
      },
      getUnconfirmedAttendance(){
          return getMyUnConfirmedAttendanceList().then((ret)=>{
              console.log(ret.data);
              var that = this;
              var count = ret.data? ret.data.count: 0;
              if(count){
                  var msg = `你有${count}个考勤未签名确认`;
                  this.$dd.device.notification.confirm({
                    message: msg,
                    title: "提示",
                    buttonLabels: ['取消', '去确认'],
                    onSuccess : function(result) {
                        if(result.buttonIndex == 1){
                            that.$router.push('/hr/attendance/list');
                        }
                    }
                });
              }
          })
      },
      getLessons(){
          var params = {
              keywords: '',
              typeid: 0,
          };
         return  getHomeCoursewareList(params).then((ret)=>{
             console.log(ret);
             this.clist = ret.data;
         }) 
      },
      getzan(list){
          return list.filter((item)=>{return item.type ==0;}).length;
      },
      getshou(list){
          return list.filter((item)=>{return item.type ==1;}).length;
      },
      format(s){
       return formatSecond(s);
      },
      getPercent(lesson){
          if(lesson.lesson_type != 0){
            return 0;
          }
          var div = Math.floor(Number(lesson.speed_num)*10000/Number(lesson.total_num));
          return div/100;
      },
      hideTab(){
          //console.log(document.querySelector('#vantab'));
          document.querySelector('#vantab').classList.remove('tab-hide');
      },
      toDetail(id){
          this.$router.push(`/courseware-detail?id=${id}`);
      },
      getLessonIcon(lessonType){
          switch (lessonType){
              case 0:
                  return require('../assets/images/video-s.png');
              case 1:
                  return require('../assets/images/ppt-s.png');
              case 2:
                  return require('../assets/images/word-s.png');
              case 3:
                  return require('../assets/images/pdf-s.png');
              default:
                  return '';
          }
      },
      getLessonTypeName(lessonType){
          switch (lessonType){
              case 0:
                  return '视频';
              case 1:
                  return 'ppt';
              case 2:
                  return 'word';
              case 3:
                  return 'pdf';
              default:
                  return '其他';
          }
      },
  },
}
</script>

<style scoped>
    @import url('../assets/css/common.css');
    /* :root {
      --van-button-primary-background-color: red;
    } */
    .container{
        bottom: 50px;
    }
    header{
        height: 16.8rem;
        background-color: #725efd;
        overflow: hidden;
    }
  
  .my-swipe{
      margin: 2rem auto 1rem auto;
  }
/*  .my-swipe .van-swipe-item{
      border-radius: 4px; 
  } */
  .my-swipe .van-swipe-item img{
      width: 34.5rem;
      height: 13.7rem;
      border-radius: 6px; 
    }
    .notice{
        border-radius: 4px;
        /* background-color: #f00; */
    }
    .search{
        display: none;
        margin: 10px auto 0;
    }
    .search img{
        width: 100%;
    }
    .menus{
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        margin: 1.3rem auto;
    }
    .menus .item{
        width: 6.9rem;
        margin-top: 1rem;
        margin-bottom:1rem;
        text-align: center;
        font-size: 1.3rem;
    }
    .menus .item:nth-child(5n){
        margin-right: 0;
    }
    .menus img{
        display: block;
        width: 4.2rem;
        height:4.2rem;
        margin: 0 auto 0.3rem;
    }
    .container{
       padding-bottom: 60px; 
    }
</style>